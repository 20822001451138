<template>
  <div>
    <!-- 公共头部 -->
   <Header></Header>

    <div class="section1">
      <div
        class="item"
        :class="[cIndex == index ? 'active' : '']"
        @click="changeCindex(index)"
        v-for="(item, index) in cateList"
        :key="index"
      >
        {{ item.title }}
      </div>
    </div>

    <ul class="list">
      <li class="item" v-for="(item, index) in orderList" :key="index">
        <div class="box1">
          订单号：{{ item.order }}
          <font class="red" v-if="item.status == '未付款'">（待付款）</font>
          <font class="blue" v-if="item.status == '已付款'">（已付款）</font>
          <font class="red" v-if="item.status == '已取消'">（已取消）</font>
          <font class="black" v-if="item.status == '退款中'"
            >（退款审核中）</font
          >
          <font class="red" v-if="item.status == '退款失败'">（退款失败）</font>
          <font class="red" v-if="item.status == '已退款'">（已退款）</font>
          <font class="black" v-if="item.status == '已过期'">（已过期）</font>
        </div>
        <router-link :to="'/order/info?order=' + item.order" class="box2">
          <div class="pic">
            <img class="icon" :src="item.img" />
          </div>
          <div class="content">
            <div class="title">{{ item.subject_title }}</div>
            <div class="info">
              <p>{{ item.title }}</p>
              <p>下单时间：{{ item.addtime }}</p>
            </div>
          </div>
        </router-link>
        <div class="box3">
          <span class="text">单价：¥{{ item.money }}</span>
          <span class="text">实付款：¥{{ item.sfmoney }}</span>
        </div>
        <div class="box4">
          <template v-if="item.status == '未付款'">
            <router-link :to="'/pay_info?order=' + item.order+'&uid='+uid" class="btn"
              >立即支付</router-link
            >
		
            <div
              class="btn btn1"
              @click="cancel(index)"
              v-if="item.classes_id == '0'"
            >
              取消订单
            </div>
          </template>
          <template v-if="item.status == '已付款'">
            <router-link
              :to="'/invoiceReceipt/receive?id=' + item.id"
              class="btn"
              >申请发票</router-link
            >
            <router-link
              :to="'/order/refund?order=' + item.order"
              class="btn btn1"
              v-if="item.classes_id == '0'"
              >申请退款</router-link
            >
          </template>
          <template v-if="item.status == '已取消'">
            <div :data-order="item.order" :to="'/regist?uid='+uid+'&order='+item.order" class="btn" @click="agren">重新报名</div>
          </template>
          <template v-if="item.status == '退款中'">
            <div class="btn" @click="cancelRefund(index)">取消申请</div>
          </template>
          <template v-if="item.status == '退款失败'">
            <router-link
              :to="'/order/refund?order=' + item.order"
              class="btn btn1"
              >再次申请</router-link
            >
          </template>
          <template class="text" v-if="item.status == '已退款'"
            >已退款</template
          >
          <template class="text" v-if="item.status == '已过期'"
            >已过期</template
          >
        </div>
      </li>
    </ul>
    <wd-pagination v-model="page" :total="total"></wd-pagination>
    <div class="blank_80"></div>
    <!-- 公共底部 -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "@/components/Public/Header.vue";
import Footer from "@/components/Public/Footer.vue";
import wx from 'weixin-js-sdk'
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      title: "",
      isWx: false,
      cateList: [
        {
          title: "全部",
          status: 0,
        },
        {
          title: "待付款",
          status: 1,
        },
        {
          title: "已付款",
          status: 2,
        },
        {
          title: "已过期",
          status: 3,
        },
        {
          title: "退款",
          status: 4,
        },
      ],
      cIndex: 0,
      limit: 10,
      page: 1,
      total: 0,
      orderList: [],
    };
  },
  watch: {
    page() {
      if (this.$route.path == "/order/list") {
        this.getOrderList();
      }
    },
    cIndex() {
      if (this.$route.path == "/order/list") {
        this.getOrderList();
      }
    },
  },
  computed: {
    uid() {
      this.$store.commit('saveUid',this.$route.query.uid)
      return this.$route.query.uid;
    },
  },
  activated() {
    this.getUserInfo();

  },
  mounted() {
	  	this.getEnv()
  },
  deactivated() {
    this.cIndex = 0;
    this.page = 1;
    this.total = 0;
    this.orderList = [];
  },
  methods: {
    agren(e){
      console.log(e);
      let order = e.target.dataset.order;
      	wx.miniProgram.getEnv(function(res){
      					if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/webview/web?src=https://tanpei.ks265.net/#/personal_regist'+'&uid='+uid});
               			}else{
							   
						}
      				  });
    },
    // 获取环境
    getEnv() {
      var ua = navigator.userAgent.toLowerCase();
	 
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
		wx.miniProgram.getEnv((res) => {
			console.log(res);
          if (res.miniprogram) {
			  this.isWx = true;
        //  wx.miniProgram.navigateTo({url:'/pages/pay/pay'})
          } else {
           this.isWx = false;
		
          }
        });
      } else {
        this.isWx = false;
      }
    },
	ToPay(e){
		console.log(e);
    let userid = this.uid;
		this.$router.push({path:'/pay_info',query:{order:e,uid:userid}})
	},
    // 取消退款
    async cancelRefund(index) {
      var id = this.orderList[index].id;
      var uid = this.uid;
      var data = {
        id,
        uid,
      };
      const confirmResult = await this.$messageBox
        .confirm("是否确认取消退款申请?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .catch((error) => error);
      if (confirmResult != "confirm") return this.$toast.info("已取消操作");
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/order/cancelRefund", {
        params: data,
      });
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "取消退款：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        this.getOrderList();
        this.$toast.success("操作成功!");
      } else {
        this.$toast.error(res.data);
      }
    },
    // 取消订单
    async cancel(index) {
      var id = this.orderList[index].id;
      var uid = this.uid;
      var data = {
        id,
        uid,
      };
      const confirmResult = await this.$messageBox
        .confirm("是否确认取消订单?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .catch((error) => error);
      if (confirmResult != "confirm") return this.$toast.info("已取消操作");
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/order/cancelOrder", {
        params: data,
      });
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "取消订单：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        this.getOrderList();
        this.$toast.success("操作成功!");
      } else {
        this.$toast.error(res.data);
      }
    },
    changeCindex(cIndex) {
      this.cIndex = cIndex;
      this.page = 1;
    },
    // 获取订单列表
    async getOrderList() {
      var limit = this.limit;
      var page = this.page;
      var status = this.cateList[this.cIndex].status;
      var uid = this.uid;
      var data = {
        limit,
        page,
        status,
        uid,
      };
      this.$toast.loading("加载中...");
      const { data: res } = await this.$http.get("/order/userOrder", {
        params: data,
      });
      this.$toast.close();
      if (this.$store.state.beta) {
        console.log(
          "订单列表：" +
            new Date().getHours() +
            ":" +
            new Date().getMinutes() +
            ":" +
            new Date().getSeconds(),
          res
        );
      }
      var code = res.code;
      if (code == 1) {
        this.total = Number(res.data.count);
        var orderList = res.data.lists;
        for (var i = 0; i < orderList.length; i++) {
          orderList[i]["addtime"] = this.utils.js_date_time(
            orderList[i]["addtime"],
            2
          );
          if (
            orderList[i]["img"] &&
            orderList[i]["img"].substring(0, 4) != "http"
          ) {
            orderList[i]["img"] = this.$store.state.http + orderList[i]["img"];
          }
        }
        this.orderList = orderList;
      } else {
        this.total = 0;
        this.orderList = [];
      }
    },
    async getUserInfo() {
      if (!this.uid) {
        const confirmResult = await this.$messageBox
          .confirm("您当前未登录，请登录后再进行操作！", "登录提醒")
          .catch((error) => error);
        if (confirmResult != "confirm") {
          		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
          return ;
        }
        		wx.miniProgram.getEnv(function(res){
      				 if (res.miniprogram){
						 wx.miniProgram.navigateTo({url:'/pages/index/index'});
               			}else{
						
						}
      				  });
      } else {
        this.getOrderList();
      }
    },
  },
};
</script>

<style scoped>
.section1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f3f3f3;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.section1 .item {
  flex: 1;
  font-size: 0.28rem;
  color: #969897;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.8rem;
}
.section1 .item.active {
  font-size: 0.32rem;
  color: #ffffff;
  background: #86cb08;
  position: relative;
}

.list {
  padding: 0.3rem 0;
}
.list .item {
  margin-top: 0.4rem;
  padding: 0 0.3rem 0.3rem;
  background: #ffffff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.list .item:first-child {
  margin-top: 0;
}
.list .item .box1 {
  height: 0.86rem;
  display: flex;
  align-items: center;
  font-size: 0.26rem;
  color: #333333;
}
.list .item .box1 .blue {
  color: #009d30;
}
.list .item .box1 .black {
  color: #c7c7c7;
}
.list .item .box1 .red {
  color: #ff2e2e;
}
.list .item .box2 {
  display: flex;
  align-items: flex-start;
}
.list .item .box2 .pic {
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
}
.list .item .box2 .pic .icon {
  max-height: 100%;
}
.list .item .box2 .content {
  margin-left: 0.3rem;
}
.list .item .box2 .content .title {
  font-size: 0.32rem;
  color: #333333;
  margin-bottom: 0.12rem;
}
.list .item .box2 .content .info {
  font-size: 0.26rem;
  color: #333333;
  line-height: 0.44rem;
}
.list .item .box3 {
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.list .item .box3 .text {
  font-size: 0.26rem;
  color: #888888;
}
.list .item .box3 .text:last-child {
  color: #333333;
  margin-left: 0.3rem;
}
.list .item .box4 {
  margin-top: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.list .item .box4 .btn {
  width: 1.4rem;
  height: 0.44rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.24rem;
  color: #333333;
  border: 0.02rem solid #333333;
  border-radius: 0.5rem;
  margin-right: 0.3rem;
}
.list .item .box4 .btn:last-child {
  margin-right: 0;
}
.list .item .box4 .btn1 {
  color: #ff6000;
  border: 0.02rem solid #ff6000;
}
</style>